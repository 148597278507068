<template>
  <base-modal id="modal-deactivate-document">
    <div class="module">
      <h4>Deactivate Parent Document</h4>
    </div>
    <p>Deactivating this document will also remove it from the clients and locations that are inheriting it.</p>
    <div class="button-group close-top"><a class="button warning" @click="deactivate">Deactivate </a><a class="button secondary" @click="closeModal">Cancel</a></div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import documentService from "@/services/document.service";
export default {
  name: "DeactivateParentDocumentModal",
  components: { BaseModal },
  props: {
    template: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      clientList: [],
      locationList: [],
    };
  },
  computed: {
    totalList() {
      return this.clientList.length + this.locationList.length;
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  methods: {
    closeModal() {
      micromodal.close("modal-deactivate-document");
    },
    async deactivate() {
      await documentService.changeDocumentStatus(this.template.id, "inactive");
      this.$emit("deactivate");
      this.closeModal();
    },
  },
};
</script>
