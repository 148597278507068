var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"table-id":_vm.$props.tableid,"headers":_vm.headers,"entries":_vm.allTemplates,"loading":_vm.$props.loading},scopedSlots:_vm._u([{key:"noContents",fn:function(){return [_c('p',[_vm._v("There are no documents added yet, please add one now!")])]},proxy:true},{key:"documentName",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.previewTemplate(item.id)}}},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('i',{staticClass:"icon-external-link right-icon"})]),(_vm.inherited(item))?_c('span',{staticClass:"light"},[_vm._v(" (Inherited)")]):_vm._e(),(item.optional)?_c('span',{staticClass:"light"},[_vm._v(" (Optional) ")]):_vm._e()]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"light"},[_vm._v(" "+_vm._s(_vm.status(item.status)))])]}},{key:"version",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'versionPreview', query: { clientID: _vm.$route.params.id, locationID: _vm.$route.params.locationID, documentID: item.originalID } }}},[_vm._v("V"+_vm._s(item.version)),_c('i',{staticClass:"icon-external-link right-icon"})])]}},{key:"dateUpdated",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDateTime")(item.updated_at)))]}},{key:"locations",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showLocationsModal(item.id, item)}}},[_c('span',[_vm._v(_vm._s(_vm.$props.locationList.length - item.locations.length)+" Locations")]),_vm._v(" "),_c('i',{})]),_c('location-assignment',{attrs:{"list":_vm.assignmentList,"assignments":_vm.returnSelectedLocations(item),"modal-id":_vm.setModelID(item.id),"template-i-d":item.id,"client":true},on:{"change":function($event){return _vm.updateAssignmentList($event)},"saveLocations":function($event){return _vm.saveUpdatedLocations($event,item)}}})]}},{key:"meatball",fn:function(ref){
var item = ref.item;
return [_c('meatball',{attrs:{"id":item.id,"large":"","long":""},on:{"selected":_vm.meatballSelected}},[_c('menu-item',{attrs:{"label":"Edit","show":true,"on-click":function () { return _vm.editDoc(item); }}}),_c('menu-item',{attrs:{"label":"Publish","show":item.status === 'draft',"on-click":function () { return _vm.publishDoc(item); }}}),_c('menu-item',{attrs:{"label":"Remove","show":_vm.inherited(item),"on-click":_vm.removeInheritedTemplateModal}}),_c('menu-item',{attrs:{"label":"Deactivate","show":!_vm.inherited(item),"on-click":_vm.openDeactivateTemplateModal}})],1)]}}])}),_c('deactivate-parent-document-modal',{attrs:{"template":_vm.selected},on:{"deactivate":_vm.reloadDocumentList}}),_c('remove-inherited-document-modal',{attrs:{"template":_vm.selected}}),_c('document-used-modal',{attrs:{"template":_vm.selected}}),_c('document-preview',{attrs:{"pdf":false,"downloadable":true,"preview":false,"templateid":_vm.previewID},on:{"pdfGenerationComplete":_vm.pdfGenerationComplete,"changePreview":_vm.pdfGenerationComplete}}),_c('CreateEditDocument',{attrs:{"edit":_vm.createEdit.edit,"template-id":_vm.createEdit.templateId,"client-id":_vm.createEdit.clientID,"location-id":_vm.createEdit.locationID,"optional":_vm.createEdit.optional},on:{"onSave":_vm.saveDocument}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }