<template>
  <base-modal :id="$props.modalId">
    <div v-if="$props.list && $props.list.length <= 0" class="module-large">
      <h3>{{ componentLabel }} Assignments</h3>
      <p>No {{ componentLabel.toLowerCase() }}s to assign</p>
    </div>
    <div v-else class="module-large">
      <h3>{{ componentLabel }} Assignments</h3>
      <div class="multi-input-group">
        <div class="inner no-left-space">
          <div class="radio-scoped">
            <input id="location-assignments-all" :checked="radioSelect === 'all'" value="all" type="radio" name="location-assignments" @click="changeSelection" />
            <label for="location-assignments-all">
              <span class="label-container">
                All {{ componentLabel.toLowerCase() }}s
                <span class="secondary-line">{{ componentLabel }}s added in the future will be automatically assigned</span>
              </span>
            </label>
          </div>
          <div class="radio-scoped">
            <input id="location-assignments-some" :checked="radioSelect === 'some'" value="some" type="radio" name="location-assignments" @click="changeSelection" />
            <label for="location-assignments-some">
              <span class="label-container">
                Only Certain {{ componentLabel }}s<span class="secondary-line">Select from current set of {{ componentLabel.toLowerCase() }}s only</span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div v-if="radioSelect === 'some'">
        <div class="checkbox-scoped">
          <input id="select-all" v-model="checkbox" type="checkbox" @change="toggleSelectAll" />
          <label for="select-all"><span class="label-container">Select All</span> </label>
        </div>
        <div class="grid">
          <div class="col-8">
            <div class="field">
              <label> <input v-model="filter" type="text" :placeholder="placeholder" /></label>
            </div>
          </div>
        </div>
        <div class="checkbox-list">
          <div v-for="item in filteredList" :key="item.id">
            <div class="checkbox-scoped">
              <input :id="item.id" v-model="assignedList"  :value="item.id" type="checkbox" @change="emit" />
              <label :for="item.id">
                <span class="label-container">{{ item.name }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <p class="light">
        <span v-if="radioSelect === 'some'">{{ assignedList.length }} </span>
        <span v-else>All </span>
        <span v-if="assignedList.length === 1">{{ componentLabel }}</span>
        <span v-else>{{ componentLabel }}s</span> Selected
      </p>
    </div>
    <div class="document-actions">
      <div class="button-group">
        <button class="button" @click="updateLocations">Save</button>
        <button class="button secondary" @click="closeModel">Cancel</button>
      </div>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";

export default {
  name: "LocationAssignment",
  components: { BaseModal },
  props: {
    client: { type: Boolean, required: false, default: false },
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
    assignments: {
      type: Array,
      required: false,
      default: () => [],
    },
    modalId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: function () {
    return {
      radioSelect: "some",
      assignedList: [],
      filter: "",
      checkbox: false,
    };
  },
  computed: {
    isDDI() {
      return !this.$route.params.id && !this.$route.params.locationID;
    },
    placeholder() {
      return `Filter - ${this.componentLabel} Name`;
    },
    filteredList() {
      if (this.$props.list) {
        return this.$props.list.filter((item) => item.name.toLowerCase().includes(this.filter.toLowerCase()));
      }
      return [];
    },
    componentLabel() {
      return this.isDDI ? "Client" : "Location";
    },
    radioSelectedType() {
      return this.$props.list.length == this.$props.assignments.length ? this.radioSelectAll() : this.radioSelectSome();
    },
  },
  watch: {
    assignments(newValue) {
      this.assignedList = [...newValue];
      this.checkbox = false;
      this.$emit("assignmentChange");
    },
  },
  mounted() {
    this.radioSelectedType;
  },
  methods: {
    radioSelectAll() {
      this.checkbox = false;
      this.assignedList = [];
      this.radioSelect = "all";
    },
    radioSelectSome() {
      this.checkbox = false;
      this.assignedList = this.$props.assignments.map((data)=>data.id);
      this.radioSelect = "some";
    },
    closeModel() {
      this.$props.list.length == this.$props.assignments.length ? this.radioSelectAll() : this.radioSelectSome();
      micromodal.close(this.$props.modalId);
    },
    emit() {
      this.$emit("change", this.assignedList);
    },
    toggleSelectAll() {
      this.assignedList = this.checkbox ? this.filteredList.map((item) => item.id) : [];
      this.$emit("change", this.assignedList);
    },
    changeSelection(e) {
      this.radioSelect = e.target.value;
      if (e.target.value === "all") {
        this.assignedList = this.filteredList.map((item) => item.id);
        this.checkbox = false;
        this.emit();
      } else {
        this.assignedList = [];
        this.checkbox = false;
        this.filterText = "";
        this.emit();
      }
    },
    updateLocations() {
      const listAll = this.$props.list.map((item) => item.id);
      this.assignedList = this.radioSelect == "all" ? listAll : this.assignedList;
      this.$emit("saveLocations", { list: this.assignedList, select: this.radioSelect });
      this.closeModel();
    },
  },
};
</script>
<style scoped>
.radio-scoped input[type="radio"] {
  width: 15px;
  cursor: pointer;
}

div.radio-scoped {
  padding: 10px;
}
.radio-scoped label {
  display: inline;
}
.radio-scoped > input[type="radio"] {
  width: 18px;
  height: 18px;
}
.secondary-line {
  padding-left: 30px;
}

div.checkbox-scoped {
  padding: 0 10px 10px 10px;
}
.checkbox-scoped input[type="checkbox"] {
  width: 15px;
  cursor: pointer;
}
.checkbox-scoped label {
  display: inline;
}
.checkbox-scoped > input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
input[type="checkbox"]:focus {
  box-shadow: none !important;
}
</style>
