<template>
  <base-modal id="modal-remove-document">
    <div class="module">
      <h4>Remove Inherited Document</h4>
    </div>
    <p>Are you sure you want to remove this inherited Document? It can be added back.</p>
    <div class="button-group close-top"><a class="button" @click="remove">Remove</a><a class="button secondary" @click="closeModal">Cancel</a></div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import documentService from "@/services/document.service";
import { mapGetters } from "vuex";
export default {
  name: "RemoveInheritedDocumentModal",
  components: { BaseModal },
  props: {
    template: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mounted() {
    micromodal.init({
      onShow: function () {
        document.body.classList.add("no-scroll");
      },
      onClose: function () {
        document.body.classList.remove("no-scroll");
      },
    });
  },
  computed: {
    ...mapGetters(["getFullName"]),
  },
  methods: {
    isLocation() {
      return !!this.$route.params.locationID;
    },
    closeModal() {
      micromodal.close("modal-remove-document");
    },
    async removeFromLocation() {
      await documentService.setRemoveOverrideLocations([this.$route.params.locationID], this.$props.template.id, this.getFullName, this.$route.params.id);
    },
    async removeFromClientAndLocations() {
      await documentService.setRemoveOverrideClients([this.$route.params.id], this.$props.template.id, this.getFullName);
    },
    remove() {
      this.isLocation() ? this.removeFromLocation() : this.removeFromClientAndLocations();
      this.closeModal();
    },
  },
};
</script>
